export const getUser = () => {
    const user = sessionStorage.getItem('user')
    if(user) return JSON.parse(user)
    else return null
}

export const isLogged = () => {
    return sessionStorage.getItem('isLoggedIn')
}

export const removeUser = () => {
    sessionStorage.removeItem('isLoggedIn')
    sessionStorage.removeItem('user')
}

export const setUserSession = (user) => {
    sessionStorage.setItem('isLoggedIn', true)
    sessionStorage.setItem('user', user)
}