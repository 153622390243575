import config from "./config.js";
import React from "react";
import {
    BrowserRouter as Router,
    Redirect,
    Route,
    Switch,
} from "react-router-dom";
import Login from "./features/login";
import BO from "./features/bo";
import { isLogged } from "./utils";

const App = () => {
    const isLoggedIn = isLogged();
    return (
        <>
            <Router>
                <Switch>
                    <Route exact path="/">
                        {isLoggedIn ? (
                            <Redirect to={`/${config.ENV}/caleta/`} />
                        ) : (
                            <Redirect to={`/${config.ENV}/login/`} />
                        )}
                    </Route>
                    <Route path={`/${config.ENV}/login/`} component={Login} />
                    <Route path={`/${config.ENV}/caleta/`} component={BO} />
                </Switch>
            </Router>
        </>
    );
};

export default App;

