import axios from "axios";
import config from "../config.js";

export default axios.create({
  baseURL: process.env.REACT_APP_URL,
  headers: {
    Authorization: config.AUTH_HEADER,
  },
});

