import config from "../../config.js";
import React, { useState } from "react";
import {
    GlobalStyles,
    CssBaseline,
    AppBar,
    Toolbar,
    Button,
    Typography,
    Container,
    Stack,
    Paper,
    TextField,
    MenuItem,
    InputLabel,
    FormControl,
    Select,
    Box,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import TableData from "../../components/Table";
import Loading from "../../components/Loading";
import Toastify from "toastify-js";
import "toastify-js/src/toastify.css";
import axios from "../../api";
import Swal from "sweetalert2";
import { isLogged, removeUser } from "../../utils";
import Footer from "../../components/Footer";
import logo from "../../images/logo.png";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import dayjs from "dayjs";
import { CSVLink } from "react-csv";
import moment from "moment";

const BO = () => {
    const dateFrom = new Date();
    const dateTo = new Date();
    dateTo.setDate(dateTo.getDate() + 1);

    let dateF = new Date(dateFrom.toLocaleDateString());
    let dateT = new Date(dateTo.toLocaleDateString());
    let dfSplit = dateF.toISOString().split("T");
    let dtSplit = dateT.toISOString().split("T");

    const [lobby, setLobby] = useState("");
    const [gameType, setGameType] = useState("");
    const [selectedDateFrom, setSelectedDateFrom] = useState(dfSplit[0]);
    const [selectedDateTo, setSelectedDateTo] = useState(dtSplit[0]);
    const [accountType, setAccountType] = useState("");
    const [isLoading, setIsLoading] = useState(false);
    const [data, setData] = useState([]);
    const [gridData, setGridData] = useState([
        { id: 1, firstName: "John", lastName: "Doe", age: 25 },
        { id: 2, firstName: "Jane", lastName: "Doe", age: 30 },
        { id: 3, firstName: "Bob", lastName: "Smith", age: 35 },
    ]);
    const columns = [
        { field: "GameDate", headerName: "Game Date", flex: 1, minWidth: 200 },
        { field: "GameProvider", headerName: "Game Provider", flex: 1 },
        { field: "GameId", headerName: "Game ID", flex: 1 },
        { field: "AccountName", headerName: "Account Name", flex: 1 },
        { field: "OutletCode", headerName: "Outlet Code", flex: 1 },
        { field: "AccountType", headerName: "Account Type", flex: 1 },
        { field: "PlayStatus", headerName: "Play Status", flex: 1 },
        { field: "GameName", headerName: "Game Name", flex: 1 },
        { field: "TotalBet", headerName: "Total Bet", flex: 1 },
        { field: "TotalPayout", headerName: "Total Payout", flex: 1 },
        { field: "JW1", headerName: "JW1", flex: 1 },
        { field: "JW2", headerName: "JW2", flex: 1 },
        { field: "JW3", headerName: "JW3", flex: 1 },
        { field: "JW4", headerName: "JW4", flex: 1 },
        { field: `PC1`, headerName: "PC1", flex: 1 },
        { field: "PC2", headerName: "PC2", flex: 1 },
        { field: "PC3", headerName: "PC3", flex: 1 },
        { field: "PC4", headerName: "PC4", flex: 1 },
    ];
    const handleDateFromChange = (event) => {
        setSelectedDateFrom(event.target.value);
    };

    const handleDateToChange = (event) => {
        setSelectedDateTo(event.target.value);
    };

    const handleChangeType = (e) => {
        setAccountType(e.target.value);
    };
    const handleGameName = (event) => {
        setLobby(event.target.value);
        if (event.target.value === 1) {
            setGameType("SPADE");
        } else if (event.target.value === 2) {
            setGameType("KM");
        } else if (event.target.value === 3) {
            setGameType("PGSOFT");
        } else {
            setGameType("");
        }
    };

    const [timeFrom, setTimeFrom] = useState(
        dayjs().set("hour", 6).set("minute", 0).set("second", 0),
    );
    const [timeTo, setTimeTo] = useState(
        dayjs().set("hour", 5).set("minute", 59).set("second", 59),
    );
    const handleDownloadCSV = () => {
        setGridData([...gridData]);
        csvLink.current.link.click();
    };
    const handleGenerateBtn = () => {
        let data2;
        let tf = timeFrom["$d"];
        let tf2 = tf.toString().split(" ");
        let tt = timeTo["$d"];
        let tt2 = tt.toString().split(" ");
        setIsLoading(true);

        const dateFrom = new Date(`${selectedDateFrom}T${tf2[4]}`);
        const dateTo = new Date(`${selectedDateTo}T${tt2[4]}`);
        const timeDiff = dateTo - dateFrom;
        const hoursDiff = timeDiff / (1000 * 60 * 60);
        if (hoursDiff > 24) {
            setIsLoading(false);
            Toastify({
                text: `Date range exceeded 24 hours.`,
                className: "toast-error",
                position: "right",
            }).showToast();
            setData([]);
        } else if (selectedDateFrom > selectedDateTo) {
            setIsLoading(false);
            Toastify({
                text: `Invalid Date Range.`,
                className: "toast-error",
                position: "right",
            }).showToast();
            setData([]);
        } else if (accountType === "") {
            setIsLoading(false);
            Toastify({
                text: `Account Type is required field.`,
                className: "toast-error",
                position: "right",
            }).showToast();
            setData([]);
        } else {
            let dateTimeFrom = `${selectedDateFrom} ${tf2[4]}`;
            let dateTimeTo = `${selectedDateTo} ${tt2[4]}`;

            let result = new Promise((resolve, reject) => {
                axios
                    .get(
                        `${config.API_URL}?dateFrom=${dateTimeFrom}&dateTo=${dateTimeTo}&accountType=${accountType}&key=${config.KEY}`,
                    )
                    .then((res2) => resolve(res2))
                    .catch((error) => reject(error));
            });
            result
                .then((response) => {
                    if (response.data.results === null) {
                        setIsLoading(false);
                        Swal.fire({
                            icon: "warning",
                            title: `No data found`,
                            text: `No data available during the period.`,
                        });
                        setData([]);
                    } else {
                        if (gameType !== "") {
                            data2 = response.data.results
                                .filter((result) => {
                                    const gameIdPrefix =
                                        result.GameId.split("_")[0];
                                    return (
                                        gameIdPrefix === gameType &&
                                        result !== undefined
                                    ); // Moved the result !== undefined check here
                                })
                                .map((result) => ({
                                    AccountName: result.AccountName,
                                    AccountType:
                                        result.AccountType === 1
                                            ? "RGP"
                                            : "LandBased",
                                    GameDate: moment
                                        .utc(result.GameDate)
                                        .format("L HH:mm:ss"),
                                    GameId: result.GameId,
                                    GameName: result.GameName,
                                    GameProvider: result.GameProvider,
                                    JW1: result.JW1,
                                    JW2: result.JW2,
                                    JW3: result.JW3,
                                    JW4: result.JW4,
                                    OutletCode: result.OutletCode,
                                    PC1: result.PC1,
                                    PC2: result.PC2,
                                    PC3: result.PC3,
                                    PC4: result.PC4,
                                    PlayStatus: result.PlayStatus,
                                    TotalBet: result.TotalBet,
                                    TotalPayout: result.TotalPayout,
                                }));
                        } else {
                            data2 = response.data.results.map((result) => ({
                                AccountName: result.AccountName,
                                AccountType:
                                    result.AccountType === 1
                                        ? "RGP"
                                        : "LandBased",
                                GameDate: moment
                                    .utc(result.GameDate)
                                    .format("L HH:mm:ss"),
                                GameId: result.GameId,
                                GameName: result.GameName,
                                GameProvider: result.GameProvider,
                                JW1: result.JW1,
                                JW2: result.JW2,
                                JW3: result.JW3,
                                JW4: result.JW4,
                                OutletCode: result.OutletCode,
                                PC1: result.PC1,
                                PC2: result.PC2,
                                PC3: result.PC3,
                                PC4: result.PC4,
                                PlayStatus: result.PlayStatus,
                                TotalBet: result.TotalBet,
                                TotalPayout: result.TotalPayout,
                            }));
                        }

                        if (data2.length === 0) {
                            Swal.fire({
                                icon: "warning",
                                title: "Oops...",
                                text: "There is no data to be displayed in this date",
                            });
                            setIsLoading(false);
                            setData([]); // Set data to empty array if data2 is empty
                        }
                        setIsLoading(false);
                        setData(data2);
                    }
                })
                .catch((error) => {
                    setIsLoading(false);
                    console.log(error);
                    Swal.fire({
                        icon: "error",
                        title: `Code: ${error.response.data.responseCode}`,
                        text: `Error ${error.response.data.responseCode} - ${error.response.data.responseStatus}`,
                    });
                    setData([]);
                });
        }
    };
    const PC1 = data.reduce((acc, row) => acc + (row.PC1 || 0), 0);
    const PC2 = data.reduce((acc, row) => acc + (row.PC2 || 0), 0);
    const PC3 = data.reduce((acc, row) => acc + (row.PC3 || 0), 0);
    const PC4 = data.reduce((acc, row) => acc + (row.PC4 || 0), 0);
    const JW1 = data.reduce((acc, row) => acc + (row.JW1 || 0), 0);
    const JW2 = data.reduce((acc, row) => acc + (row.JW2 || 0), 0);
    const JW3 = data.reduce((acc, row) => acc + (row.JW3 || 0), 0);
    const JW4 = data.reduce((acc, row) => acc + (row.JW4 || 0), 0);
    const totalPayout = data.reduce(
        (acc, row) => acc + (row.TotalPayout || 0),
        0,
    );
    const totalBet = data.reduce((acc, row) => acc + (row.TotalBet || 0), 0);
    const csvLink = React.createRef();

    const addRow = [
        {
            AccountName: "",
            AccountType: "",
            GameDate: "",
            GameID: "",
            GameName: "Total:",
            GameProvider: "",
            JW1: JW1,
            JW2: JW2,
            JW3: JW3,
            JW4: JW4,
            OutletCode: "",
            PC1: PC1,
            PC2: PC2,
            PC3: PC3,
            PC4: PC4,
            PlayStatus: "",
            TotalBet: totalBet,
            TotalPayout: totalPayout,
        },
    ];

    const getRowId = (row) =>
        row.GameId || row.AccountName || row.GameName || null || "";

    const Logout = () => {
        Swal.fire({
            icon: "warning",
            title: `LOGOUT?`,
            text: `Are you sure you want to logout?`,
            showCancelButton: true,
            confirmButtonText: "Yes",
        }).then((result) => {
            if (result.isConfirmed === true && result.isDismissed === false) {
                removeUser();
                setTimeout(() => {
                    window.location.href = "/";
                }, 1000);
            }
        });
    };

    const session = isLogged();

    if (session === null) {
        setTimeout(() => {
            window.location.href = "/";
        }, 2000);
        return <Loading open={true} />;
    } else {
        return (
            <>
                <GlobalStyles
                    styles={{
                        ul: { margin: 0, padding: 0, listStyle: "none" },
                    }}
                />
                <CssBaseline />
                <AppBar
                    position="static"
                    color="default"
                    elevation={0}
                    sx={{ background: "transparent" }}
                >
                    <Toolbar sx={{ flexWrap: "wrap" }}>
                        <img src={logo} className="images-logo2" />
                        <Typography
                            variant="h6"
                            color="white"
                            noWrap
                            sx={{ flexGrow: 1, p: 2 }}
                        >
                            Back Office
                        </Typography>
                        <nav></nav>
                        <Button
                            variant="contained"
                            sx={{
                                my: 1,
                                mx: 1.5,
                                background: "rgb(32,51,84,1)",
                            }}
                            className="btnLogout"
                            onClick={Logout}
                        >
                            Logout
                        </Button>
                    </Toolbar>
                </AppBar>
                <Container maxWidth="xl">
                    <Paper elevation={3} sx={{ p: 2, m: 3 }}>
                        <p>Generate Reports</p>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <Stack
                                direction="row"
                                spacing={2}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                <TextField
                                    id="df"
                                    label="Date From"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleDateFromChange}
                                    defaultValue={selectedDateFrom}
                                    type="date"
                                    sx={{ width: 200 }}
                                />
                                <TimePicker
                                    ampm={false}
                                    openTo="hours"
                                    views={["hours", "minutes", "seconds"]}
                                    inputFormat="HH:mm:ss"
                                    mask="__:__:__"
                                    label="Time From"
                                    value={timeFrom}
                                    onChange={(newValue) => {
                                        setTimeFrom(newValue);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                                <TextField
                                    id="dt"
                                    label="Date To"
                                    InputLabelProps={{
                                        shrink: true,
                                    }}
                                    onChange={handleDateToChange}
                                    value={selectedDateTo}
                                    type="date"
                                    sx={{ width: 200 }}
                                />
                                <TimePicker
                                    ampm={false}
                                    openTo="hours"
                                    views={["hours", "minutes", "seconds"]}
                                    inputFormat="HH:mm:ss"
                                    mask="__:__:__"
                                    label="Time To"
                                    value={timeTo}
                                    onChange={(newValue2) => {
                                        setTimeTo(newValue2);
                                    }}
                                    renderInput={(params) => (
                                        <TextField {...params} />
                                    )}
                                />
                                {/* <FormControl sx={{ width: 300 }}>
                                    <InputLabel id="game-lobby">Game Lobby</InputLabel>
                                    <Select
                                        labelId="game-lobby"
                                        id="game-lobby"
                                        label="Game Lobby"
                                        value={lobby}
                                        onChange={handleGameName}
                                    >
                                        <MenuItem value={0}>All</MenuItem>
                                        <MenuItem value={1}>Spade Gaming</MenuItem>
                                        <MenuItem value={2}>Kingmaker</MenuItem>
                                        <MenuItem value={3}>PG Soft</MenuItem>
                                    </Select>
                                </FormControl> */}
                                <FormControl sx={{ width: 300 }}>
                                    <InputLabel id="account-type">
                                        Account Type
                                    </InputLabel>
                                    <Select
                                        labelId="account-type"
                                        id="account-type"
                                        value={accountType}
                                        label="Account Type"
                                        onChange={handleChangeType}
                                    >
                                        <MenuItem value={2}>All</MenuItem>
                                        <MenuItem value={0}>LandBased</MenuItem>
                                        <MenuItem value={1}>RGP</MenuItem>
                                    </Select>
                                </FormControl>
                                <Button
                                    variant="contained"
                                    onClick={handleGenerateBtn}
                                >
                                    Generate
                                </Button>
                                <Button
                                    variant="contained"
                                    onClick={handleDownloadCSV}
                                    sx={{ m: 1, background: "gray" }}
                                >
                                    Download File
                                </Button>
                            </Stack>
                        </LocalizationProvider>
                    </Paper>
                </Container>
                <Paper elevation={3} sx={{ p: 2, m: 3 }}>
                    <DataGrid
                        rows={data.concat(addRow)}
                        getRowId={getRowId}
                        columns={columns}
                        pageSize={10}
                        rowsPerPageOptions={[5, 10]}
                        rowCount={data.length}
                    />
                    <CSVLink
                        data={data}
                        headers={[
                            { label: "Game Date", key: "GameDate" },
                            { label: "Game Provider", key: "GameProvider" },
                            { label: "Game ID", key: "GameId" },
                            { label: "Account Name", key: "AccountName" },
                            { label: "Outlet Code", key: "OutletCode" },
                            { label: "Account Type", key: "AccountType" },
                            { label: "Play Status", key: "PlayStatus" },
                            { label: "Game Name", key: "GameName" },
                            { label: "Total Bet", key: "TotalBet" },
                            { label: "Total Payout", key: "TotalPayout" },
                            { label: "JW1", key: "JW1" },
                            { label: "JW2", key: "JW2" },
                            { label: "JW3", key: "JW3" },
                            { label: "JW4", key: "JW4" },
                            { label: "PC1", key: "PC1" },
                            { label: "PC2", key: "PC2" },
                            { label: "PC3", key: "PC3" },
                            { label: "PC4", key: "PC4" },
                        ]}
                        ref={csvLink}
                    />
                </Paper>
                <Loading open={isLoading} />
                <Footer sx={{ mt: 6, mb: 4 }} />
                {config.ENV === "sta" && (
                    <Box
                        sx={{
                            background: "red",
                            p: 2,
                            position: "absolute",
                            right: 0,
                            top: 100,
                        }}
                    >
                        <Typography variant="h6" sx={{ color: "white" }}>
                            UAT Environment
                        </Typography>
                    </Box>
                )}
            </>
        );
    }
};

export default BO;
